import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'src/store';
import { useCallback, useEffect, useState } from 'react';
import { updateCoupons } from 'src/useCase/coupon';

export type FormValues = {
  ownerId: { label: string; id: string } | any;
  headline: string | null;
  description: string | null;
  offerShort: string | null;
  status: string | null;
  expirationDate: string | null;
  quantity: number | null;
  image: any | null;
  code: string;
};

type Data = Response.CouponEntity | null;

const useFormDetail = (data: Data, users: any) => {
  const [loadingHood, setLoadingHood] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [loadingUser, setLoadingUser] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    status: Yup.string().required(),
    headline: Yup.string().required(),
    description: Yup.string().required(),
    expirationDate: Yup.string().required(),
  });

  const getDefaultValueUser = useCallback(
    (data: Response.CouponEntity | null) => {
      const user = users.find((v) => String(v.id) === data?.ownerId);
      if (!user) return null;

      return {
        label: String(user?.nick_name),
        id: String(user?.id),
      };
    },
    [users],
  );

  useEffect(() => {
    if (!getDefaultValueUser(data)?.id) {
      setLoadingUser(true);
    }
    setLoadingUser(false);
  }, [data, users]);

  useEffect(() => {
    const labelUser = data?.owner
      ? {
          label: data?.owner?.nick_name,
          id: data?.ownerId,
        }
      : '';
    form.reset({
      ownerId: labelUser || '',
      code: data?.code || '',
      headline: data?.headline,
      description: data?.description,
      offerShort: data?.offerShort,
      status: data?.status,
      expirationDate: data?.expirationDate || new Date().toDateString(),
      quantity: Number(data?.quantity),
      image: data?.image,
    });
  }, [data]);

  const form = useForm<FormValues>({
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const submitForm = () => {
    form.handleSubmit(async (values) => {
      console.log('values', values);
      await dispatch(
        updateCoupons({
          id: data?.id as string,
          ...values,
        }),
      );
    })();
  };

  return { form, submitForm, loadingHood };
};

export default useFormDetail;
