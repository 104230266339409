import React from 'react';

import FormDetail from './Form';
import { useAppSelector } from 'src/store';

type Props = {
  users: {
    entities: Response.UserEntity[];
    loading: boolean;
  };
};

const Main: React.FC<Props> = ({ users }) => {
  const entities = useAppSelector((state) => state.domain.coupon.detail);
  const props = {
    users,
    loading: entities.loading,
    entity: entities.entity,
  };

  return (
    <React.Fragment>
      <FormDetail {...props} />
    </React.Fragment>
  );
};

export default Main;
