import React, { useContext } from 'react';
import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';

import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import PostAddIcon from '@mui/icons-material/PostAdd';
import FacebookIcon from '@mui/icons-material/Facebook';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import Report from '@mui/icons-material/Report';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Comment from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import AdsIcon from '@mui/icons-material/FeaturedPlayList';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import MapIcon from '@mui/icons-material/Map';
import Search from '@mui/icons-material/Search';
import Gif from '@mui/icons-material/Gif';
import { SidebarContext } from 'src/theme/SidebarContext';
import { PATH as PATH_FACEBOOK } from 'src/pages/facebook';
import { PATH as EVENT_LIST } from 'src/pages/event/EventList';
import { PATH as BANNER_LIST } from 'src/pages/actionBanner/ActionBannerList';
import { LocalStorage } from 'src/utils/LocalStorage';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`,
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity',
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`,
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);

  return (
    <>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/"
                  startIcon={<DesignServicesTwoToneIcon />}
                >
                  Dashboard
                </Button>
              </ListItem>

              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/user-analytics"
                  startIcon={<AnalyticsIcon />}
                >
                  User Analytics
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/hood-analytics"
                  startIcon={<AnalyticsIcon />}
                >
                  Hood Analytics
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        {LocalStorage.isSuperAdmin() && (
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                User management
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/user_list"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    User List
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/post_list"
                    startIcon={<PostAddIcon />}
                  >
                    Post List
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/hood_list"
                    startIcon={<ConnectWithoutContactIcon />}
                  >
                    Hood List
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/hood_images"
                    startIcon={<ConnectWithoutContactIcon />}
                  >
                    Hood Image
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/report_list"
                    startIcon={<Report />}
                  >
                    Report List
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/comment_list"
                    startIcon={<Comment />}
                  >
                    Comment List
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/share_list"
                    startIcon={<ShareIcon />}
                  >
                    Share Post List
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/ads_list"
                    startIcon={<AdsIcon />}
                  >
                    Ads List
                  </Button>
                </ListItem>

                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/link_list"
                    startIcon={<LocalCafeIcon />}
                  >
                    Local Service
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/custom-maps"
                    startIcon={<MapIcon />}
                  >
                    Custom Maps
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={PATH_FACEBOOK.LIST}
                    startIcon={<FacebookIcon />}
                  >
                    Facebook Integration
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={EVENT_LIST}
                    startIcon={<EmojiEventsIcon />}
                  >
                    Event List
                  </Button>
                </ListItem>

                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={BANNER_LIST}
                    startIcon={<HeatPumpIcon />}
                  >
                    Action Banner
                  </Button>
                </ListItem>

                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/app_list"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    App List
                  </Button>
                </ListItem>

                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/activity_list"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    Activity List
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/activity_search_history"
                    startIcon={<Search />}
                  >
                    Activity Search History
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/coupons"
                    startIcon={<Gif />}
                  >
                    Coupons
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
        )}
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
