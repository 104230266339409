import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import couponListActions from 'src/store/domain/coupon/list';
import couponDetailActions from 'src/store/domain/coupon/detail';
import couponRequest from 'src/api/requests/coupon';
import appActions from 'src/store/ui/app';
import { toast } from 'react-toastify';
import qs from 'query-string';
import history from 'src/libs/history';

interface FetchResources extends SearchParams.Coupon {}

const getParams = (args: FetchResources) => {
  const { take, page } = args;

  const params: Payload.CouponList = {
    ...args,
    take,
    page,
  };

  return params;
};

export const fetchResources = createAsyncThunk<void, any>(
  'get_coupon_list',
  async (args, { dispatch }) => {
    try {
      dispatch(couponListActions.updateLoading(true));

      const params = getParams(args);
      const res = await couponRequest.getList(_.omitBy(params, _.isEmpty));

      const { data, meta } = res.data;

      if (data) {
        dispatch(couponListActions.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appActions.updatePagination({
            page: meta.page,
            take: meta.take,
            itemCount: meta.itemCount,
            pageCount: meta.pageCount,
          }),
        );
      }
      dispatch(couponListActions.updateLoading(false));
    } catch (err) {
      dispatch(couponListActions.updateLoading(false));
    }
  },
);

export const deleteCoupons = createAsyncThunk<void, string>(
  'delete_coupons',
  async (id, { dispatch }) => {
    try {
      const res = await couponRequest.deleteCoupons(id);
      if (res.status != 200) {
        toast.error('There was an error deleting');
      } else {
        toast.success('Successfully!');

        await dispatch(
          fetchResources(
            getParams(qs.parse(history.location.search) as FetchResources),
          ),
        );
      }
    } catch (err) {
      toast.error('There was an error deleting');
    }
  },
);

export const createResource = createAsyncThunk<void, any>(
  'create_coupons',
  async (body, { dispatch }) => {
    try {
      const res = await couponRequest.createCoupon(body);
      if (res.status != 200) {
        toast.error('There was an error creating');
      } else {
        toast.success('Successfully!');
      }
    } catch (err) {
      toast.error('There was an error creating');
    }
  },
);

export const fetchDetail = createAsyncThunk<void, string>(
  'get_coupons_detail',
  async (id, { dispatch }) => {
    try {
      dispatch(couponDetailActions.updateLoading(true));
      const res = await couponRequest.getDetail(id);

      if (res?.data) {
        await dispatch(couponDetailActions.updateEntity(res?.data));
      }
    } finally {
      dispatch(couponDetailActions.updateLoading(false));
    }
  },
);

export const updateCoupons = createAsyncThunk<void, any>(
  'update_coupons',
  async (values, { dispatch }) => {
    try {
      const res = await couponRequest.update(values);
      if (res.status != 200) {
        toast.error('There was an error updating');
      } else {
        toast.success('Successfully!');
        await dispatch(fetchResources(values.id));
      }
    } catch (err) {
      console.log(err);
      toast.error('There was an error updating');
    }
  },
);
