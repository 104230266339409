import HTTPRequest from '../index';
import { formatDate } from 'src/utils/helpers';
import _ from 'lodash';

const apiPath = '/v1/coupons';

class CouponRequest extends HTTPRequest {
  getList(params: Payload.CouponList) {
    return this.get<any>(apiPath, { params });
  }

  deleteCoupons(id: string) {
    return this.delete(`${apiPath}/${id}`);
  }

  createCoupon(data: any) {
    let formData = new FormData();
    formData.append('ownerId', data.ownerId);
    formData.append('headline', data.headline);
    formData.append('description', data.description);
    formData.append('offerShort', data.offerShort);
    formData.append('status', data.status);

    if (data.expirationDate)
      formData.append('expirationDate', formatDate(data.expirationDate));

    formData.append('quantity', data.quantity);

    if (data.image) formData.append('image', data.image);

    return this.post(`${apiPath}/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getDetail(id: string) {
    return this.get<Response.CouponEntity>(`${apiPath}/${id}`);
  }

  update(data: any) {
    let formData = new FormData();
    if (data?.ownerId?.id) {
      formData.append('ownerId', _.get(data, 'ownerId.id', null));
    }
    formData.append('headline', data.headline);
    formData.append('description', data.description);
    formData.append('offerShort', data.offerShort);
    formData.append('status', data.status);

    if (data.expirationDate)
      formData.append('expirationDate', formatDate(data.expirationDate));

    formData.append('quantity', data.quantity);

    if (data.image) formData.append('image', data.image);

    return this.put(`${apiPath}/${data.id}/update`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default new CouponRequest();
