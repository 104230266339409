import * as Yup from 'yup';
import { useFormik } from 'formik';

import { useAppDispatch } from 'src/store';
import { createResource } from 'src/useCase/coupon';
import history from 'src/libs/history';
import { PATH } from 'src/pages/coupon';

export type FormValue = {
  ownerId: string;
  headline: string;
  description: string;
  offerShort: string;
  status: string;
  expirationDate: string;
  quantity: number;
  image: string;
};

const useForm = () => {
  const dispatch = useAppDispatch();
  const defaultValue: FormValue = {
    ownerId: '',
    headline: '',
    description: '',
    offerShort: '',
    status: '',
    expirationDate: new Date().toDateString(),
    quantity: 1,
    image: '',
  };

  const validationSchema = Yup.object().shape({
    status: Yup.string().required(),
    ownerId: Yup.string().required(),
    headline: Yup.string().required(),
    description: Yup.string().required(),
    expirationDate: Yup.string().required(),
  });

  const formik = useFormik<FormValue>({
    initialValues: {
      ...defaultValue,
    },
    validationSchema,
    async onSubmit(values) {
      await dispatch(createResource({ ...values }));
      history.push(PATH.LIST);
    },
    validateOnChange: true,
  });

  return { formik };
};

export default useForm;
