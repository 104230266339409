import { combineReducers } from '@reduxjs/toolkit';

import user from './user';
import post from './post';
import hood from './hood';
import statistics from './statistics';
import report from './report';
import comment from './comment';
import share from './share';
import ads from './ads';
import link from './link';
import facebook from './facebook';
import event from './event';
import actionBanner from './actionBanner';
import app from './app';
import customMaps from './customMaps';
import activity from './activity';
import hoodImage from './hoodImage';
import coupon from './coupon';

export default combineReducers({
  user,
  post,
  hood,
  statistics,
  report,
  comment,
  share,
  ads,
  link,
  facebook,
  event,
  actionBanner,
  app,
  customMaps,
  activity,
  hoodImage,
  coupon,
});
