import {
  Grid,
  styled,
  TextField,
  IconButton,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Select,
  FormControl,
  FormHelperText,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card } from 'src/components/common';
import useForm from './useForm';
import history from 'src/libs/history';
import { useTranslation } from 'react-i18next';
import { DatePicker, LoadingButton } from '@mui/lab';
import { useAppDispatch } from 'src/store';
import { searchUsers } from 'src/useCase/userList';

type Props = {
  users: {
    entities: Response.UserEntity[];
    loading: boolean;
  };
};

const FormCreate: React.FC<Props> = ({ users }) => {
  const [getStatus, setStatus] = React.useState('');
  const dispatch = useAppDispatch();
  const refImage = useRef<File | null>(null);
  const [openStatus, setOpenStatus] = React.useState(false);
  const { formik } = useForm();
  const [openUser, setOpenUser] = useState<boolean>(false);

  const {
    handleSubmit,
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    isSubmitting,
  } = formik;

  const { t } = useTranslation();
  const loadingUser = users.loading;
  const listUser = useMemo(() => {
    if (openUser && users.entities.length) {
      return users.entities.map((v) => {
        return {
          label: v.nickName,
          id: v.id,
        };
      });
    }
  }, [users.entities, openUser]);
  const [searchUser, setSearchUser] = useState<string>('');
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchUser) {
        dispatch(searchUsers(searchUser));
      }
    }, 1200);

    return () => clearTimeout(delayDebounceFn);
  }, [searchUser]);

  const handleChangeSelectStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
    setFieldValue('status', event.target.value);
  };

  const handleOpenStatus = () => {
    setOpenStatus(true);
  };

  const handleCloseStatus = () => {
    setOpenStatus(false);
  };

  const status = [
    {
      value: 'pending_approval',
      text: 'Pending approval',
    },
    {
      value: 'active',
      text: 'Active',
    },
    {
      value: 'paused',
      text: 'Paused',
    },
    {
      value: 'archived',
      text: 'Archived',
    },
  ];

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <Card>
          <Grid container marginLeft={0} spacing={2}>
            <Grid item xs={5}>
              <Autocomplete
                id="combo-box-demo"
                options={listUser || []}
                onChange={(e, v) => {
                  setFieldValue('ownerId', v?.id);
                }}
                onInputChange={(e: any) => setSearchUser(e.target?.value)}
                loading={loadingUser}
                onOpen={() => setOpenUser(true)}
                onClose={() => setOpenUser(false)}
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    type="text"
                    name="ownerId"
                    {...params}
                    label="Owner Id"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingUser ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              {touched.ownerId && Boolean(errors.ownerId)}
            </Grid>

            <Grid item xs={10}>
              <TextField
                fullWidth
                type="text"
                id="headline"
                name="headline"
                label="Headline"
                onChange={handleChange}
                error={touched.headline && Boolean(errors.headline)}
                helperText={touched.headline && errors.headline}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                type="textarea"
                minRows={5}
                id="description"
                name="description"
                label="Description"
                onChange={handleChange}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                type="textarea"
                minRows={5}
                id="offerShort"
                name="offerShort"
                label="OfferShort"
                onChange={handleChange}
                error={touched.offerShort && Boolean(errors.offerShort)}
                helperText={touched.offerShort && errors.offerShort}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                type="number"
                id="quantity"
                name="quantity"
                defaultValue={1}
                label="Quantity"
                onChange={handleChange}
                error={touched.quantity && Boolean(errors.quantity)}
                helperText={touched.quantity && errors.quantity}
              />
            </Grid>
            <Grid item xs={5}>
              <DatePicker
                value={values.expirationDate}
                label="Expiration Date"
                onChange={(value) => {
                  setFieldValue('expirationDate', value?.toString(), true);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id="expirationDate"
                    name="expirationDate"
                    label="Expiration Date"
                    error={
                      touched.expirationDate && Boolean(errors.expirationDate)
                    }
                    helperText={touched.expirationDate && errors.expirationDate}
                  />
                )}
              />
            </Grid>
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="image"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      refImage.current = event.currentTarget.files[0];
                      setFieldValue('image', event.currentTarget.files[0]);
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              {touched.image && errors.image && (
                <FormHelperText className="Mui-error">
                  {errors.image}
                </FormHelperText>
              )}
              <img
                width={120}
                height="auto"
                src={
                  refImage.current
                    ? URL.createObjectURL(refImage.current)
                    : 'https://placehold.jp/120x120.png?text=120%20x%20120'
                }
              />
            </Grid>
            <Grid item xs={5}>
              <FormControl
                sx={{ m: 1, minWidth: '100%', margin: 0 }}
                error={touched.status && Boolean(errors.status)}
              >
                <InputLabel id="controlled-open-select-status-label">
                  {t('ads_fields.status')}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="controlled-open-select-status-label"
                  id="controlled-open-select-status"
                  open={openStatus}
                  onClose={handleCloseStatus}
                  onOpen={handleOpenStatus}
                  value={getStatus}
                  name="status"
                  label={t('ads_fields.status')}
                  onChange={handleChangeSelectStatus}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {status.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
                {touched.status && Boolean(errors.status) && (
                  <FormHelperText>{errors.status}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="submit"
                variant="contained"
                size="small"
                loading={isSubmitting}
                loadingPosition="start"
                startIcon={<SaveIcon />}
              >
                Create
              </LoadingButton>

              <LoadingButton
                variant="contained"
                size="small"
                style={{ marginLeft: 10 }}
                onClick={history.back}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

export default FormCreate;
