import React from 'react';

import Container from 'src/containers/CouponCreate';
import { ContentWrapper } from 'src/components/common';

const Page: React.FC = () => {
  return (
    <ContentWrapper title={'Create Coupons'}>
      <Container />
    </ContentWrapper>
  );
};

export const PATH = '/coupons/create' as const;
export default Page;
