import { Grid, styled, CircularProgress, IconButton } from '@mui/material';
import ModeIcon from '@mui/icons-material/Mode';
import React, { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import { Card } from 'src/components/common';
import useForm, { FormValues } from './useForm';
import history from 'src/libs/history';
import {
  AutoCompleteField,
  DatePickerField,
  InputField,
} from 'src/components/form';
import SelectField from 'src/components/form/SelectField';
import { useAppDispatch } from 'src/store';
import { PhotoCamera } from '@mui/icons-material';
import { searchUsers } from 'src/useCase/userList';

type Props = {
  users: {
    entities: Response.UserEntity[];
    loading: boolean;
  };
  entity: Response.CouponEntity | null;
  loading: boolean;
};

const FormDetail: React.FC<Props> = (props) => {
  const { users, entity, loading } = props;

  const { form, submitForm } = useForm(entity, users.entities);
  const { control, formState, setValue } = form;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [refImage, setRefImage] = React.useState<File | null>(null);
  const status = [
    {
      value: 'pending_approval',
      label: 'Pending approval',
    },
    {
      value: 'active',
      label: 'Active',
    },
    {
      value: 'paused',
      label: 'Paused',
    },
    {
      value: 'archived',
      label: 'Archived',
    },
  ];
  const [openUser, setOpenUser] = useState<boolean>(false);

  const getUsers = useMemo(() => {
    return users.entities.map((v) => {
      return {
        label: String(v.nickName),
        id: String(v.id),
      };
    });
  }, [users]);

  useEffect(() => {
    if (entity) {
      (async () => {
        if (entity.ownerId) {
          await dispatch(searchUsers(entity.owner?.nick_name));
        } else {
          await dispatch(searchUsers(''));
        }
        // @ts-ignore
      })();
    }
  }, [entity]);

  return (
    <Wrapper>
      {loading ? (
        <CircularProgress />
      ) : (
        <Card>
          <Grid container marginLeft={0} spacing={2}>
            <Grid item xs={10}>
              <InputField<FormValues>
                controller={{ control, name: 'code' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.code || '',
                  disabled: true,
                }}
                label="Code"
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <AutoCompleteField<FormValues>
                controller={{ control, name: 'ownerId' }}
                options={getUsers || []}
                onOpen={() => setOpenUser(!openUser)}
                onClose={() => setOpenUser(!openUser)}
                callbackOnchange={(v) => {
                  dispatch(searchUsers(v));
                }}
                loading={users.loading}
                label="Owner ID"
                size="medium"
              />
            </Grid>
            <Grid item xs={10}>
              <InputField<FormValues>
                controller={{ control, name: 'headline' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.headline || '',
                }}
                label="Headline"
                size="medium"
              />
            </Grid>
            <Grid item xs={10}>
              <InputField<FormValues>
                controller={{ control, name: 'description' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.description || '',
                }}
                label="Description"
                size="medium"
              />
            </Grid>
            <Grid item xs={10}>
              <InputField<FormValues>
                controller={{ control, name: 'offerShort' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.offerShort || '',
                }}
                label="Offer Short"
                size="medium"
              />
            </Grid>

            <Grid item xs={3}>
              <InputField<FormValues>
                controller={{ control, name: 'quantity' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.quantity || '',
                  type: 'number',
                }}
                label="Quantity"
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <DatePickerField<FormValues>
                controller={{ control, name: 'expirationDate' }}
                label="Expiration Date"
                size="medium"
              />
            </Grid>
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="bannerMobileLeaderBoard"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      setValue('image', event.currentTarget.files[0], {
                        shouldDirty: true,
                      });
                      setRefImage(event.currentTarget.files[0]);
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              <img
                width={320}
                height="auto"
                src={
                  refImage
                    ? URL.createObjectURL(refImage)
                    : `${process.env.REACT_APP_MEDIA_URL}${entity?.image}`
                }
                loading="lazy"
                onError={(e) => {
                  if (e.currentTarget.src) {
                    e.currentTarget.title = 'no image';
                  }
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <SelectField<FormValues>
                controller={{ control, name: 'status' }}
                options={status}
                selectProps={{
                  defaultValue: entity?.status,
                }}
                label={'Status'}
                size="medium"
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="button"
                variant="contained"
                size="small"
                disabled={!formState.isDirty}
                style={{ marginTop: 22 }}
                loading={formState.isSubmitting}
                loadingPosition="start"
                startIcon={<ModeIcon />}
                onClick={submitForm}
              >
                {t('button.update')}
              </LoadingButton>

              <LoadingButton
                variant="contained"
                size="small"
                style={{ marginTop: 22, marginLeft: 10 }}
                onClick={history.back}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

export default FormDetail;
