import React from 'react';

import Main from './Main';
import { useAppSelector } from 'src/store';

const Container: React.FC = () => {
  const props = useAppSelector((state) => {
    return {
      users: state.domain.user.list,
    };
  });

  return <Main {...props} />;
};

export default Container;
